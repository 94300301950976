export const ARTEFACT_NAME_MAP = {
  files: "file",
  links: "link",
  folders: "folder",
  labels: "label",
  forms: "form",
  invoices: "invoice",
  packages: "package",
  products: "product",
  coupons: "coupon",
  schedulers: "scheduler",
};
