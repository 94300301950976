import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const FolderMoveIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    width={24}
    height={24}
    className={classNames("fill-current", className)}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 5.44772 4.44772 5 5 5H8.92963C9.26399 5 9.57622 5.1671 9.76168 5.4453L10.8711 7.1094C11.242 7.6658 11.8665 8 12.5352 8H19C19.5523 8 20 8.44772 20 9V11.5C20 11.7762 20.2239 12 20.5 12L21.5 12C21.7761 12 22 11.7761 22 11.5V9C22 7.34315 20.6569 6 19 6H12.5352L11.4258 4.3359C10.8694 3.5013 9.93269 3 8.92963 3H5C3.34314 3 2 4.34315 2 6V17C2 18.6569 3.34315 20 5 20H9.5C9.77614 20 10 19.7762 10 19.5V18.5C10 18.2239 9.77614 18 9.5 18H5C4.44772 18 4 17.5523 4 17V6ZM21.8536 16.3891C22.0488 16.5844 22.0488 16.9009 21.8536 17.0962L17.6109 21.3388C17.4157 21.5341 17.0991 21.5341 16.9038 21.3388L16.1967 20.6317C16.0014 20.4365 16.0014 20.1199 16.1967 19.9246L18.3787 17.7427L12.3 17.7497C12.0238 17.7497 11.8 17.5188 11.8 17.2427V16.2427C11.8 15.9665 12.0168 15.7453 12.2929 15.7453L18.3787 15.7427L16.1967 13.5607C16.0014 13.3654 16.0014 13.0488 16.1967 12.8536L16.9038 12.1465C17.0991 11.9512 17.4157 11.9512 17.6109 12.1465L21.8536 16.3891Z"
    />
  </svg>
);

export default FolderMoveIcon;
