import BroadcastIcon from "@components/Icons/BroadcastIcon";
import SmallModal from "@components/Modals/SmallModal";

interface BroadcastModalProps {
  show: boolean;
  toggleShow: React.Dispatch<React.SetStateAction<boolean>>;
  onBroadcast: () => Promise<void>;
  isBroadcasting: boolean;
}

const BroadcastModal = ({
  show,
  toggleShow,
  isBroadcasting,
  onBroadcast,
}: BroadcastModalProps) => {
  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      title={isBroadcasting ? "Stop Broadcasting?" : "Start Broadcasting?"}
      description={
        isBroadcasting
          ? "When you stop a broadcast, it's instantly removed from all your current and future clients."
          : "When you broadcast a file or a link, it's instantly shared with all your current and future clients."
      }
      icon={BroadcastIcon}
      onAction={onBroadcast}
      onActionText={isBroadcasting ? "Stop Broadcast" : "Start Broadcast"}
    />
  );
};

export default BroadcastModal;
