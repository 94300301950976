import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DragDropContextProps } from "react-beautiful-dnd";
import {
  startBaseLibraryItemBroacast,
  stopBaseLibraryItemBroadcast,
} from "api-services/definitions/library";
import { useApi } from "api-services/endpoints";
import axios from "axios";
import classNames from "classnames";
import { doc, updateDoc } from "firebase/firestore";
import { compact, omit, pick } from "lodash";
import { useRouter } from "next/router";
import { useDocument } from "swr-firebase";
import { Folder } from "types/db";

import { useAuth } from "@contexts/auth";
import {
  useLibraryBaseItemById,
  useLibraryItems,
  useLibraryItemsOnPath,
} from "@hooks/data/library";
import useAccessType from "@hooks/use-access-type";
import { shareItemWithClient } from "@hooks/use-library-item";
import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";
import { compatDB as db } from "@lib/firebase-config";
import { MoveItemsType } from "@lib/shared-types";
import { getArtifactLink } from "@lib/utils/artifacts";
import { ARTEFACT_NAME_MAP } from "@lib/utils/library";
import pluralHelper from "@lib/utils/pluralHelper";

import EmptyListLarge from "@components/EmptyListLarge";
import ChevronRight from "@components/Icons/ChevronRight";
import FolderMoveIcon from "@components/Icons/FolderMoveIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import LibraryItemDetails from "@components/Library/LibraryItemDetails";
import { LinkFormModal, SharingModal } from "@components/Modals";
import BigModal from "@components/Modals/BigModal";
import DeleteModal from "@components/Modals/DeleteModal";
import MultipleActions from "@components/MultipleActions";
import {
  DnDSelectableList,
  SelectableListContextProvider,
  useSelectableListContext,
} from "@components/SelectableList";

import BroadcastModal from "./BroadcastModal";
import FilesAndLinksListItem, {
  LibraryItemBasicInfo,
  LibraryItemIcon,
  LibraryItemTitle,
} from "./FilesAndLinksListItem";
import RenameFileOrLinkModal from "./RenameFileOrLinkModal";

export enum LibraryAction {
  Share = "SHARE",
  Broadcast = "BROADCAST",
  Rename = "RENAME",
  Edit = "EDIT",
  Delete = "DELETE",
  Move = "MOVE",
}

export type ModalInfoType = { action: LibraryAction; index: number };

const getItemNameProperty = (item) => {
  if (item?.type === "files") return "fileName";
  return "title";
};

const MoveItemsModal: React.FC<{
  open: boolean;
  close: () => void;
  setMovingIds: React.Dispatch<SetStateAction<string[]>>;
}> = ({ close, open, setMovingIds }) => {
  const [toPath, setToPath] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);

  const {
    query: { folderIds },
  } = useRouter();

  const { move } = useMoveItems(setMovingIds);

  const toPathParts = toPath?.split("/");

  const { baseLibraryItems } = useLibraryItems();
  const { data } = useLibraryItemsOnPath(toPath);
  const { data: item } = useLibraryBaseItemById(
    toPath ? toPathParts?.[toPathParts.length - 1] : undefined
  );

  const { selectedIds, selected, clearSelected } = useSelectableListContext();

  const currentItems = data
    .filter((x) => !selectedIds.includes(x.id))
    .filter((x) => x.status !== "system");

  const folderIdsOnThePath = toPath?.split("/") || [];
  const foldersOnThePath = folderIdsOnThePath.map((id) =>
    baseLibraryItems.find((item) => item.id === id)
  ) as Folder[];
  const breadcrumbs = [
    {
      name: "Library",
      path: undefined,
    },
    ...foldersOnThePath.map((folder) => ({
      name: folder.title,
      path: [folder.path, folder.id].filter(Boolean).join("/"),
    })),
  ];

  const fromPath = folderIds?.join("/");

  const moveItems = useCallback(async () => {
    clearSelected();
    setIsLoading(true);
    await move(fromPath, toPath, selected, "modal");
    setIsLoading(false);
    close();

    // TODO error handling
  }, [fromPath, toPath, selected, clearSelected, move]);

  return (
    <BigModal
      className="flex flex-col"
      show={open}
      toggleShow={close}
      onAction={moveItems}
      title="Move items"
      onActionText="Move here"
      onActionLoading={isLoading}
      isActionDisabled={
        fromPath === toPath ||
        selected.map((item) => item.id).includes(toPath ?? "")
      }
    >
      <div className="flex mb-4">
        {breadcrumbs.map((x, i) => {
          const isLast = i !== breadcrumbs.length - 1;
          return (
            <>
              <div
                className={classNames(
                  isLast && "underline cursor-pointer text-grey-500"
                )}
                onClick={() => setToPath(x.path)}
                key={x.name}
              >
                {x.name}
              </div>
              {isLast && <span className="mx-2 text-grey-500">/</span>}
            </>
          );
        })}
      </div>
      {item && (
        <div className="bg-grey-950 px-6 py-4 rounded-xl mb-2">
          <LibraryItemDetails
            standalone
            item={omit(item, "createdAt")}
            type={item.type}
          />
        </div>
      )}
      <div className="flex-1 overflow-auto">
        {currentItems.map((item) => {
          const isFolder = item.type === "folders";
          const extraProps = isFolder
            ? {
                onClick: () =>
                  setToPath((ex) => [ex, item.id].filter(Boolean).join("/")),
              }
            : {};
          return (
            <div
              {...extraProps}
              className={classNames(
                "hover:bg-action-900 focus:outline-none focus:bg-action-900 transition duration-150 ease-in-out",
                "border-t first:border-t-0 border-gray-200",
                "flex h-20 items-center px-4",
                isFolder && "cursor-pointer"
              )}
              key={item.id}
            >
              <LibraryItemIcon data={item} />
              <div className="flex flex-col ml-4">
                <LibraryItemTitle data={item} />
                <LibraryItemBasicInfo data={item} />
              </div>
              {isFolder && <ChevronRight className="ml-auto h-4 w-4" />}
            </div>
          );
        })}
      </div>
    </BigModal>
  );
};

const useMoveItems = (
  setMovingIds: React.Dispatch<SetStateAction<string[]>>
) => {
  const { uid } = useAuth();
  const snackbar = useSnackbar();
  const move = useCallback(
    async (from: string, to: string, items: any[], type: string) => {
      setMovingIds(items.map((item) => item.id));
      await axios.post(`/api/v1/users/${uid}/library/move`, {
        items: items.map((it) => pick(it, "id", "type")),
        from,
        to,
      } as MoveItemsType);
      analytics.track({
        event: "library_moved_items",
        properties: {
          count: items.length,
          type,
        },
      });
      snackbar.showMessage(
        `${pluralHelper(items.length, "Item")} succesfully moved`
      );
      setTimeout(() => setMovingIds([]), 1000);
    },
    [setMovingIds, uid]
  );

  return { move };
};

// TODO Props
const FilesAndLinksList = ({
  data,
  base,
  hideSharedColumn,
  selectable,
  isDragDisabled,
}) => {
  const { firestoreUser: user, uid, oid } = useAuth();
  const { hasFullAccess, hasElevatedAccess } = useAccessType();
  const canDeleteLibraryItems = hasFullAccess;
  const [modalInfo, setModalInfo] = useState<ModalInfoType>();
  const { selected, clearSelected, selectedIds } = useSelectableListContext();
  const [deletingMultiple, setDeletingMultiple] = useState(false);
  const [movingIds, setMovingIds] = useState<string[]>([]);
  const selectedItem = useMemo(() => data[modalInfo?.index], [modalInfo, data]);
  const itemNameProperty = modalInfo ? getItemNameProperty(selectedItem) : null;
  const canMove = hasElevatedAccess;
  const canRename = hasElevatedAccess;
  const canEdit = hasElevatedAccess;
  const canDrag = hasElevatedAccess;

  const { move } = useMoveItems(setMovingIds);

  const {
    query: { folderIds },
  } = useRouter();

  useEffect(() => {
    clearSelected();
  }, [folderIds, clearSelected]);

  const handleDeleteMultiple = useCallback(async () => {
    const paths = selected.map((x) => x.__refPath);
    await Promise.all(
      paths.map((path) =>
        updateDoc(doc(db, path), { status: "deleted", updatedAt: new Date() })
      )
    );
    clearSelected();
    analytics.track({
      event: "library_deleted_multiple",
      properties: {
        count: selected.length,
      },
    });
  }, [selected, clearSelected]);

  const path = modalInfo ? selectedItem?.__refPath : null;

  const { update } = useDocument(path, {
    listen: true,
  });

  const closeModal = useCallback(() => setModalInfo(undefined), []);

  const sharedUrl =
    selectedItem &&
    (selectedItem.type === "links"
      ? selectedItem.url
      : getArtifactLink(user, selectedItem.id, selectedItem.type));

  const type = ARTEFACT_NAME_MAP[selectedItem?.type];

  const renameItem = useCallback(
    (name: string) => {
      analytics.track(`library_inline_renamed_${type}`);
      return update({ [itemNameProperty]: name, updatedAt: new Date() });
    },
    [update, itemNameProperty, type]
  );

  const deleteItem = useCallback(async () => {
    await update({ status: "deleted", updatedAt: new Date() });
    analytics.track(`library_inline_deleted_${type}`);
    closeModal();
    // TODO If file, do we really want to remove from storage, or should we do it on demand? HIPAA ?
  }, [update, closeModal, type]);

  const { apiCall: startBroadcast } = useApi(startBaseLibraryItemBroacast);
  const { apiCall: stopBroadcast } = useApi(stopBaseLibraryItemBroadcast);

  const onBroadcast = useCallback(async () => {
    if (selectedItem.broadcasted) {
      await stopBroadcast(
        {
          userId: oid!,
          resourceType: selectedItem.type,
          resourceId: selectedItem.id,
        },
        {},
        {}
      );
    } else {
      await startBroadcast(
        {
          userId: oid!,
          resourceType: selectedItem.type,
          resourceId: selectedItem.id,
        },
        {},
        {}
      );
    }
    analytics.track(`library_inline_deleted_${type}`);
    closeModal();
    // TODO If file, do we really want to remove from storage, or should we do it on demand? HIPAA ?
  }, [closeModal, oid, selectedItem, startBroadcast, stopBroadcast, type]);

  const share = useCallback(
    (clientId: string) => {
      analytics.track(`library_inline_shared_${type}`);

      if (selectedItem.clientId)
        return update({ status: "shared", updatedAt: new Date() });

      return shareItemWithClient(
        uid,
        selectedItem.id,
        selectedItem.type,
        clientId
      );
    },
    [selectedItem, uid, update, type]
  );

  const onDragEnd: DragDropContextProps["onDragEnd"] = useCallback(
    async (result) => {
      const targetId = result.combine?.draggableId;

      const source = data[result.source.index];

      const targetFolder = data.find((item) => item.id === targetId);
      if (
        !targetId ||
        !targetFolder ||
        targetFolder.status === "system" ||
        targetFolder.type !== "folders" ||
        !source
      ) {
        setMovingIds([]);
        return;
      }

      const items = selected.length ? selected : [source];

      clearSelected();
      await move(
        folderIds?.join("/"),
        [folderIds, targetId].filter(Boolean).join("/"),
        items,
        "dnd"
      );
    },
    [data, selected, folderIds, move, clearSelected]
  );

  const onDragStart: DragDropContextProps["onDragStart"] = useCallback(
    async (initial) => {
      if (!selectedIds.includes(initial.draggableId)) {
        clearSelected();
      } else {
        setMovingIds(selectedIds.filter((id) => id !== initial.draggableId));
      }
    },
    [clearSelected, selectedIds]
  );

  if (!data) return null;

  return data?.length > 0 ? (
    <div>
      <MultipleActions
        label={`${pluralHelper(selected.length, "item")} selected`}
        actions={compact([
          canDeleteLibraryItems && {
            icon: <TrashIcon className="text-peach-600" />,
            onClick: () => setDeletingMultiple(true),
          },
          ...(canMove
            ? [
                {
                  icon: <FolderMoveIcon className="text-white" />,
                  onClick: () =>
                    setModalInfo({ action: LibraryAction.Move, index: null }),
                },
              ]
            : []),
        ])}
      />
      <DnDSelectableList
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        isSortDisabled
        items={data
          .filter((item) => !movingIds.includes(item.id))
          .map((item) =>
            item.link.includes("clients")
              ? { ...item, isSelectionDisabled: true }
              : item
          )}
        selectable={selectable}
        isDragDisabled={isDragDisabled || !canDrag}
        rowRenderer={(item, index) => (
          <FilesAndLinksListItem
            index={index}
            data={item}
            key={item.id}
            moveDisabled={canMove ? isDragDisabled : true}
            renameDisabled={!(canRename && data[index].type !== "links")}
            editDisabled={!(canEdit && data[index].type === "links")}
            deleteDisabled={!canDeleteLibraryItems}
            broadcastDisabled={!hasElevatedAccess}
            setModalInfo={setModalInfo}
            hideSharedColumn={hideSharedColumn}
          />
        )}
      />
      {/* TODO Rename */}
      <RenameFileOrLinkModal
        show={modalInfo?.action === LibraryAction.Rename}
        toggleShow={closeModal}
        type={type}
        name={selectedItem?.[itemNameProperty]}
        onSubmit={renameItem}
      />
      <LinkFormModal
        show={modalInfo?.action === LibraryAction.Edit}
        toggleShow={closeModal}
        link={selectedItem}
        isUserFile={selectedItem?.clientId ? !selectedItem.clientId : true}
        contactId={selectedItem?.clientId}
        libraryPath={selectedItem?.libraryPath}
        heapEventName="library_link_updated"
      />
      <DeleteModal
        show={modalInfo?.action === LibraryAction.Delete}
        toggleShow={closeModal}
        description={`This will permanently delete the ${type} and all associated Smart Actions.`}
        onDelete={deleteItem}
        artifactType={type}
        disableRouteBack
      />
      <BroadcastModal
        show={modalInfo?.action === LibraryAction.Broadcast}
        toggleShow={closeModal}
        onBroadcast={onBroadcast}
        isBroadcasting={!!selectedItem?.broadcasted}
      />
      <DeleteModal
        show={deletingMultiple}
        toggleShow={() => setDeletingMultiple(false)}
        description={`This will permanently delete this ${pluralHelper(
          selected.length,
          "item"
        )} from everywhere.`}
        onDelete={handleDeleteMultiple}
        artifactType={"item"}
        disableRouteBack
      />
      <MoveItemsModal
        open={modalInfo?.action === LibraryAction.Move}
        setMovingIds={setMovingIds}
        close={() => {
          if (modalInfo?.index === null) clearSelected();
          closeModal();
        }}
      />
      {selectedItem && (
        <SharingModal
          body={`Here is the ${type} I want to share with you:\n{{link}}`}
          show={modalInfo.action === LibraryAction.Share}
          toggleShow={closeModal}
          client={selectedItem.clientId ? { id: selectedItem.clientId } : null}
          artefactType={type}
          artefactLink={sharedUrl}
          onShare={share}
          artefactItem={selectedItem}
          expandWithClientId
        />
      )}
    </div>
  ) : (
    <>
      {base && (
        <EmptyListLarge
          title="Manage and organize your files and links"
          description="The files and links you have added in your clients' directories will appear here."
          actionLink="/contacts"
          actionTitle="Go to Clients"
          containerClassName="mt-24"
        />
      )}
    </>
  );
};

const Wrapper: React.FC = (props) => {
  return (
    <SelectableListContextProvider>
      <FilesAndLinksList {...props} />
    </SelectableListContextProvider>
  );
};

export default Wrapper;
