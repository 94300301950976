import { isEmpty, pick } from "lodash";

import { displayNameFromContact } from "./contacts";

export const getClientsSharedWithText = (clients) => {
  if (!clients || isEmpty(clients)) {
    return "Not shared with any clients yet";
  }

  let clientsText = `${displayNameFromContact(clients[0])}`;

  const totalClientsSharedWith = clients.length;
  if (totalClientsSharedWith > 1) {
    clientsText += `, +${totalClientsSharedWith - 1}`;
  }

  return clientsText;
};

// Formatting before duplicating after sharing from FilesAndLinksList
export const formatLinkData = (data) => {
  const formattedData = pick(data, [
    "description",
    "domain",
    "favicon",
    "imageUrl",
    "keywords",
    "oEmbed",
    "open_graph",
    "title",
    "twitter_card",
    "url",
    "websiteTitle",
  ]);
  return formattedData;
};
export const formatFileData = (data) => {
  const formattedData = pick(data, ["contentType", "fileName", "size", "url"]);
  return formattedData;
};
