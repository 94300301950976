import React, { FC } from "react";
import classNames from "classnames";

import { Button } from "@components/Button";

interface EmptyListLargeProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  actionLink?: string;
  actionButtonHandler?: () => void;
  actionTitle?: string;
  blank?: boolean;
  containerClassName?: string;
}

const EmptyListLarge: FC<EmptyListLargeProps> = ({
  icon,
  title,
  description,
  actionLink,
  actionButtonHandler,
  actionTitle,
  blank,
  containerClassName,
}) => {
  return (
    <div className={classNames("flex-1 items-center", containerClassName)}>
      <div className="mx-auto rounded-full flex items-center justify-center">
        {icon}
      </div>
      <div className="text-center">
        <h3 className="mt-3 text-black-ink text-2xl">{title}</h3>
        <div className="mt-4 text-grey-500 text-lg mx-auto max-w-sm">
          <p>{description}</p>
        </div>
        <div className="mx-auto mt-8">
          {actionLink ? (
            <a
              href={actionLink}
              target={blank && "_blank"}
              rel="noreferrer"
              className="inline-flex rounded-lg mx-auto bg-action-700 hover:bg-action-500 transition duration-150 ease-in-out"
            >
              <div className="flex-1 flex items-center justify-between rounded-r-lg truncate">
                <div className="flex-1 px-6 py-4 truncate">
                  <p className="text-lg truncate">{actionTitle}</p>
                </div>
              </div>
            </a>
          ) : actionButtonHandler ? (
            <Button
              className="flex rounded-lg mx-auto bg-action-700 hover:bg-action-500 transition duration-150 ease-in-out"
              onClick={actionButtonHandler}
            >
              {actionTitle}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EmptyListLarge;
