import { FC, Fragment, ReactNode, useState } from "react";
import classNames from "classnames";
import { capitalize, startCase } from "lodash";

import { Dialog } from "@components/Dialog";
import ArrowIcon from "@components/Icons/ArrowIcon";
import ArrowReturnIcon from "@components/Icons/ArrowReturnIcon";
import BlockIcon from "@components/Icons/BlockIcon";
import BroadcastingIcon from "@components/Icons/BroadcastingIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import DocDraftIcon from "@components/Icons/DocDraftIcon";
import FailedIcon from "@components/Icons/FailedIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import LockOutlineIcon from "@components/Icons/LockOutlineIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import SharedIcon from "@components/Icons/SharedIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";

const getSteps = (type: string) => {
  if (type === "broadcasted") {
    return [
      {
        icon: <BroadcastingIcon />,
        status: "Broadcasted",
        description: `Automatically shared with every client by default`,
        className: "text-violet-300",
        containerClassName: "bg-violet-900",
      },
    ];
  }
  if (["file", "link", "note", "folder"].includes(type)) {
    return [
      {
        icon: <LockOutlineIcon className="text-white" />,
        status: "Private",
        description: `Every ${type} created is private first`,
        className: "text-black-ink",
        containerClassName: "bg-grey-150",
      },
      {
        icon: <SharedIcon className="text-blue-300" />,
        status: "Shared",
        description: `The ${type} is visible to your client`,
        className: "text-blue-300",
        containerClassName: "bg-blue-900",
      },
    ];
  }

  if (type === "subscription") {
    return [
      {
        icon: <ClockIcon className="text-action-500" />,
        status: "Scheduled",
        description: `The subscription is scheduled`,
        className: "text-action-500",
        containerClassName: "bg-action-800",
      },
      {
        icon: <LoopIcon className="text-white" />,
        status: "Active",
        description: `The subscription is active`,
        className: "text-green-200",
        containerClassName: "bg-green-200",
      },
      {
        icon: <CheckIcon className="text-green-300" />,
        status: "Complete",
        description: `The subscription is complete`,
        className: "text-green-300",
        containerClassName: "bg-green-600",
      },
    ];
  }

  return [
    {
      icon: <DocDraftIcon className="text-grey-500" />,
      status: "Draft",
      description: `Every ${type} is a draft first`,
      className: "text-black-ink",
      containerClassName: "bg-grey-900",
    },
    {
      icon: <ClockIcon className="text-action-500" />,
      status: "Scheduled",
      description: `${capitalize(type)} will be sent to clients later`,
      className: "text-action-500",
      containerClassName: "bg-action-800",
    },
    {
      icon: <SharedIcon className="text-blue-300" />,
      status: "Shared",
      description: `The ${type} is visible to your client`,
      className: "text-blue-300",
      containerClassName: "bg-blue-900",
    },
    {
      icon: <ViewOnIcon className="text-action-500" />,
      status: "Viewed",
      description: `Your client has viewed the ${type}`,
      className: "text-action-500",
      containerClassName: "bg-action-800",
    },
    {
      icon: <CheckIcon className="text-green-300" />,
      status: "Submitted / Paid",
      description: "Your client has completed the action",
      className: "text-green-300",
      containerClassName: "bg-green-600",
    },
  ];
};

const renderStep = (
  containerClassName: string,
  className: string,
  icon: ReactNode,
  status: string,
  description: string
) => (
  <li className="flex items-center mt-0.5">
    <div
      className={classNames(
        "w-8 h-8 rounded-full flex shrink-0 items-center justify-center",
        containerClassName
      )}
    >
      {icon}
    </div>
    <div className="ml-3">
      <strong
        className={classNames("font-medium block leading-tight", className)}
      >
        {status}
      </strong>
      <span className="text-sm text-grey-250 font-normal whitespace-nowrap">
        {description}
      </span>
    </div>
  </li>
);

const BottomSteps: FC<{ type: string }> = ({ type }) => {
  const isInvoiceType = type === "invoice";
  return (
    <>
      <div className="block">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {renderStep(
          "bg-peach-800",
          "text-peach-400",
          <FailedIcon className="text-peach-400" />,
          "Failed",
          `Processing of the payment ${type}`
        )}
        {renderStep(
          "bg-grey-900 text-grey-500",
          "text-grey-500",
          <BlockIcon />,
          "Cancelled",
          `${startCase(type)} marked cancelled, cannot be paid`
        )}
        {isInvoiceType &&
          renderStep(
            "bg-grey-900",
            "text-grey-500",
            <ArrowReturnIcon className="text-grey-500" />,
            "Refunded",
            "Refunded to the payment method on file"
          )}
      </div>
    </>
  );
};

interface StatusTooltipProps {
  className?: string;
  type: string;
  // TODO: create an ArtifactType type
  // in shared-types and use it here
  dialogContent?: ReactNode;
}

const StatusTooltip: FC<StatusTooltipProps> = ({
  className,
  type,
  dialogContent = null,
  ...rest
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const steps = getSteps(type);

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={classNames(
        className,
        "relative inline-flex align-text-top ml-0.5 mt-0.5"
      )}
      {...rest}
    >
      <InfoIcon className="inline w-4 h-4 m" />
      <Dialog
        show={showTooltip}
        className="top-0 left-0 mt-8 text-left normal-case"
        width="96"
      >
        {dialogContent || (
          <ul>
            {steps.map(
              (
                { icon, status, description, className, containerClassName },
                index
              ) => (
                <Fragment key={status}>
                  {renderStep(
                    containerClassName,
                    className,
                    icon,
                    status,
                    description
                  )}
                  {index !== steps.length - 1 && (
                    <li className="w-full ml-0.5">
                      <ArrowIcon className="transform -rotate-90 text-grey-800 w-7 h-7" />
                    </li>
                  )}
                </Fragment>
              )
            )}
            {["subscription", "invoice"].includes(type) && (
              <BottomSteps type={type} />
            )}
          </ul>
        )}
      </Dialog>
    </div>
  );
};

export default StatusTooltip;
