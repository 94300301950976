import { useEffect, useState } from "react";
import throttle from "lodash/throttle";
import tailwindConfig from "tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";

// @ts-ignore - withOpacity() returns a function for some of our colors. But typescript wants a string. Is this fine?
const { theme } = resolveConfig(tailwindConfig);

export const useMatchMedia = (size = "md") => {
  const [matches, setMatches] = useState<boolean>();

  useEffect(() => {
    const handleResize = throttle(
      () => {
        if (theme?.screens) {
          setMatches(
            window.matchMedia(`(min-width: ${theme.screens[size]})`).matches
          );
        }
      },
      50,
      { trailing: false }
    );
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  return matches;
};
