import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 4C7.58172 4 4 7.58172 4 12C4 14.0463 4.76826 15.9131 6.03207 17.3278C6.21686 17.5346 6.21914 17.8519 6.02301 18.048L5.31634 18.7547C5.12176 18.9493 4.80552 18.9509 4.61979 18.7479C2.99268 16.9693 2 14.6006 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 14.5626 21.0361 16.9001 19.451 18.6697C19.2661 18.8761 18.9471 18.8761 18.7512 18.6801L18.0436 17.9726C17.8492 17.7782 17.8495 17.4643 18.0303 17.2571C19.257 15.8512 20 14.0124 20 12C20 7.58172 16.4183 4 12 4Z" />
      <path d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 12.909 15.6968 13.7472 15.186 14.4189C15.0203 14.6368 15.0194 14.9483 15.2129 15.1419L15.9215 15.8505C16.1179 16.0469 16.4386 16.0468 16.6161 15.8332C17.4803 14.7936 18 13.4575 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 13.4966 6.54795 14.8652 7.45416 15.9162C7.63343 16.1241 7.94954 16.1215 8.14365 15.9274L8.84932 15.2217C9.04631 15.0247 9.04148 14.7064 8.86804 14.4884C8.32466 13.8054 8 12.9406 8 12Z" />
      <path d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z" />
    </svg>
  );
};

export default Icon;
