import { useMemo } from "react";
import { groupBy, keyBy, sortBy, uniq } from "lodash";
import { File } from "types/db/file";
import { Folder } from "types/db/folder";
import { LibraryItem } from "types/db/library";
import { Link } from "types/db/link";
import { LibraryResourceType } from "types/db/shared";

import { useCollection, useGetFirestoreCollectionData } from "@contexts/data";
import { displayNameFromContact } from "@lib/contacts";

import { useContacts } from "./clients";
import { loadingHelper } from "./utils";

// Get type from path, renam from ui to something else
export type LibraryListItem = (Folder | File | Link) & {
  link: string;
  type: LibraryResourceType;
};

export const mergeSharedData = (
  item: LibraryListItem,
  from: LibraryListItem
) => {
  const sharedWith = [...(item.sharedWith || []), ...(from.sharedWith || [])];
  return {
    ...item,
    sharedWith,
    sharedWithData: {
      ...(item.sharedWithData || {}),
      ...(from.sharedWithData || {}),
    },
    broadcasted: item.broadcasted || from.broadcasted,
    status: sharedWith.length ? "shared" : "private",
  };
};

const isBase = (item: LibraryItem) => !item.__refPath?.includes("clients");

const mapType = <T>(
  collection: T[] = [],
  type: "files" | "links" | "folders"
) => collection.map((item) => ({ ...item, type }));

export const useLibraryItems = () => {
  const { data: files, loading: filesIsLoading } = useCollection("files", {
    groupPropName: "coachUserId",
  });

  const { data: links, loading: linksIsLoading } = useCollection("links", {
    groupPropName: "coachUserId",
  });

  const { data: folders, loading: foldersIsLoading } = useCollection(
    "folders",
    { groupPropName: "coachUserId" }
  );

  const items = useMemo(() => {
    return [
      ...mapType(files, "files"),
      ...mapType(links, "links"),
      ...mapType(folders, "folders"),
    ].filter(
      (item) =>
        item.status !== "deleted" &&
        !item.legacy &&
        !item.__refPath.includes("contacts")
    );
  }, [files, folders, links]);

  const timelineItems: LibraryListItem[] = useMemo(
    () =>
      items
        .filter((item) => !isBase(item))
        .map((item) => ({
          ...item,
          link: `/contacts/${item.__parentId}/${item.type}/${item.id}`,
          clientId: item.__parentId,
        })),
    [items]
  );
  const baseItems: LibraryListItem[] = useMemo(
    () =>
      items
        .filter(
          (item) =>
            isBase(item) && !(item.sharedWith?.length && !item.sharedWithData)
        )
        .map((item) => ({
          ...item,
          link: `/library/${item.type}/${
            item.path && item.type === "folders"
              ? `${item.path}/${item.id}`
              : item.id
          }`,
        })),
    [items]
  );

  const foldersMap = baseItems
    .filter((item) => item.type === "folders")
    .reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr }),
      {} as Record<string, LibraryListItem>
    );

  const baseLibraryItems = baseItems.map((item) => {
    if (!item.path) return item;
    // recursive sharedWith data setting
    const pathParts = item.path.split("/");
    // TODO why could a folder be missing ?
    const pathFolders = pathParts.map((id) => foldersMap[id] || {});

    return pathFolders.reduce(mergeSharedData, item);
  });

  const allItems = useMemo(
    () => [...timelineItems, ...baseLibraryItems],
    [baseLibraryItems, timelineItems]
  );

  return {
    loading: foldersIsLoading || filesIsLoading || linksIsLoading,
    timelineItems,
    baseLibraryItems,
    allItems,
  };
};

const sorter = (a: LibraryListItem, b: LibraryListItem) => {
  if (a.type === "folders" && b.type !== "folders") return -1;
  if (b.type === "folders" && a.type !== "folders") return 1;
  return a.createdAt < b.createdAt ? 1 : -1;
};

export const useSystemFolders = () => {
  const { active: clients } = useContacts();
  const { timelineItems } = useLibraryItems();

  const activeContactsMap = useMemo(() => keyBy(clients, "id"), [clients]);
  const uniqueIds = useMemo(
    () =>
      uniq(
        timelineItems
          .map((it) => it.__parentId)
          .filter(Boolean)
          .filter((id) => activeContactsMap[id as string])
      ),
    [timelineItems, activeContactsMap]
  ) as string[];

  const systemFolders = useMemo(() => {
    return sortBy(
      uniqueIds.map(
        (id) =>
          ({
            title: displayNameFromContact(activeContactsMap[id]),
            type: "folders",
            status: "system",
            link: `/library/folders/clients/${id}`,
            path: `clients`,
            sharedWith: [id],
            id,
            __refPath: "",
          }) as LibraryListItem
      ),
      "title"
    );
  }, [activeContactsMap, uniqueIds]);

  return loadingHelper(systemFolders);
};

export const useLibraryBaseItemById = <T>(id?: string) => {
  const { baseLibraryItems } = useLibraryItems();
  return useMemo(
    () => loadingHelper(baseLibraryItems.find((item) => item.id === id) as T),
    [baseLibraryItems, id]
  );
};

export const useClientLibraryItemsByType = (clientId: string) => {
  const { allItems } = useLibraryItems();
  return useMemo(
    () =>
      loadingHelper(
        groupBy(
          allItems.filter(
            (item) =>
              item.__refPath.includes(clientId) ||
              item.sharedWith?.includes(clientId)
          ),
          "type"
        )
      ),
    [allItems, clientId]
  );
};

export const useClientSharedLibraryItems = (clientId: string) => {
  const { baseLibraryItems } = useLibraryItems();
  return useMemo(
    () =>
      loadingHelper(
        baseLibraryItems.filter((item) => item.sharedWith?.includes(clientId))
      ),
    [baseLibraryItems, clientId]
  );
};

export const useLibraryItemsOnPath = (path?: string) => {
  const { baseLibraryItems, loading } = useLibraryItems();

  // TODO Sort folders first, dates second...
  const items = useMemo(() => {
    return baseLibraryItems.filter((item) => item.path === path).sort(sorter);
  }, [baseLibraryItems, path]);

  return { data: items, loading };
};

export const useSystemFolderContent = (clientId: string) => {
  const { timelineItems } = useLibraryItems();

  const items = useMemo(
    () => timelineItems?.filter((it) => it.__refPath?.includes(clientId)),
    [clientId, timelineItems]
  );

  return loadingHelper(items);
};

export const useGetLibraryItemsForClient = ({
  clientId,
  organizationId,
  collection,
}: {
  clientId: string;
  collection: string;
  organizationId: string;
}) => {
  const { data: privateItems } = useGetFirestoreCollectionData({
    collectionName:
      clientId && organizationId
        ? `users/${organizationId}/clients/${clientId}/${collection}`
        : "",
    queryList: [],
  });

  const { data: libraryItems } = useGetFirestoreCollectionData({
    collectionName: clientId && organizationId ? collection : "",
    isCollectionGroup: true,
    queryList: [
      ["coachUserId", "==", organizationId],
      ["sharedWith", "array-contains", clientId],
    ],
  });

  const items = useMemo(() => {
    if (!privateItems || !libraryItems) return undefined;
    return [...privateItems, ...libraryItems];
  }, [privateItems, libraryItems]);

  return { data: items, loading: !items };
};
