import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { capitalize } from "lodash";

import TextFieldForm from "@components/Form/TextFieldForm";
import ModalWrappedForm from "@components/ModalWrappedForm";

type ResourceType = "file" | "link";
interface RenameFileOrLinkFormProps {
  type: ResourceType;
  name: string;
}
interface RenameFileOrLinkModalProps {
  show: boolean;
  toggleShow: React.Dispatch<React.SetStateAction<boolean>>;
  type: ResourceType;
  onSubmit: (name: string) => Promise<void>;
  name?: string;
}

const RenameFileOrLinkForm = ({ type, name }: RenameFileOrLinkFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (name) {
      setValue("name", name);
    }
  }, [name]);

  return (
    <TextFieldForm
      containerClassName="mb-4"
      label={`${capitalize(type)} name`}
      name="name"
      register={register}
      errors={errors}
    />
  );
};

const RenameFileOrLinkModal = ({
  show,
  toggleShow,
  type,
  name,
  onSubmit,
}: RenameFileOrLinkModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async ({ name }: { name: string }) => {
    setIsSubmitting(true);
    await onSubmit(name);
    setIsSubmitting(false);
    toggleShow(false);
  };

  return (
    /* @ts-expect-error: thinks props are mandatory */
    <ModalWrappedForm
      show={show}
      toggleShow={toggleShow}
      onSubmit={handleSubmit}
      title={`Rename ${type}`}
      actionTitle="Save"
      defaultValues={{ name }}
      mode="onChange"
      isLoading={isSubmitting}
      modalProps={{
        maxWidth: "max-w-sm",
      }}
      hideHeaderSeperator
    >
      <div className="mt-6">
        <RenameFileOrLinkForm type={type} name={name} />
        {/* {error && (
          <div className="mt-2 p-3 text-sm rounded-md text-peach-600 bg-peach-950 flex flex-row items-center block w-full">
            <AlertIcon className="block mr-2" />
            <p id="error">{error}</p>
          </div>
        )} */}
      </div>
    </ModalWrappedForm>
  );
};

export default RenameFileOrLinkModal;
