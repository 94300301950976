import React from "react";

import InfoBoxLabelDetailsPage from "./InfoBoxLabelDetailsPage";

const InfoBoxStatusDetailsPage = ({ formattedStatus, icon }) => {
  const displayStatus = (formattedStatus) => {
    switch (formattedStatus) {
      case "Accepted":
      case "Active":
      case "Paused":
      case "Paid":
      case "Complete":
      case "Processing":
      case "Processing via ACH":
        return (
          <p className="text-green-200">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Viewed":
        return (
          <p className="text-action-500">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Draft":
        return (
          <p className="text-grey-500">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Private":
        return (
          <p className="text-black-ink">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Broadcasted":
        return (
          <p className="text-violet-300">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Declined":
      case "Failed":
        return (
          <p className="text-peach-400">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Cancelled":
        return (
          <p className="text-peach-400">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Scheduled":
        return (
          <p className="text-action-500">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Shared":
        return (
          <p className="text-blue-300">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Maybe":
        return (
          <p className="text-action-400">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Pending":
        return (
          <p className="text-grey-400">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Invited":
        return (
          <p className="text-blue-300">
            {formattedStatus}
            {icon}
          </p>
        );
      case "System":
        return (
          <p className="text-action-500">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Canceled":
        // TODO This is very ugly switch statement
        return (
          <p className="text-grey-500">
            {formattedStatus}
            {icon}
          </p>
        );
      case "Refunded":
      case "Partially refunded":
        return (
          <p className="text-black-ink">
            {formattedStatus}
            {icon}
          </p>
        );
      default:
        return <p>No Status</p>;
    }
  };

  return (
    <InfoBoxLabelDetailsPage>
      {displayStatus(formattedStatus)}
    </InfoBoxLabelDetailsPage>
  );
};

export default InfoBoxStatusDetailsPage;
