import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25705 18.3287C2.84636 16.6049 2 14.4013 2 12C2 6.47715 6.47715 2 12 2C14.4013 2 16.6049 2.84637 18.3287 4.25705L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711L3.70711 21.7071C3.31658 22.0976 2.68342 22.0976 2.29289 21.7071C1.90237 21.3166 1.90237 20.6834 2.29289 20.2929L4.25705 18.3287ZM4 12C4 7.58172 7.58172 4 12 4C13.8487 4 15.551 4.62708 16.9056 5.68014L15.4765 7.10925C14.4957 6.41079 13.2958 6 12 6C8.68629 6 6 8.68629 6 12C6 13.2958 6.41079 14.4957 7.10925 15.4765L5.68014 16.9056C4.62708 15.551 4 13.8487 4 12ZM8.55382 14.032L10.0677 12.518C10.0236 12.3528 10 12.1792 10 12C10 10.8954 10.8954 10 12 10C12.1792 10 12.3528 10.0236 12.518 10.0677L14.032 8.55382C13.4365 8.20193 12.7418 8 12 8C9.79086 8 8 9.79086 8 12C8 12.7418 8.20193 13.4365 8.55382 14.032Z"
      />
      <path d="M17.9039 10.9245L15.8891 12.9393C15.7579 13.4846 15.515 13.9863 15.186 14.4189C15.0203 14.6368 15.0194 14.9483 15.2129 15.1419L15.9215 15.8505C16.1179 16.0469 16.4386 16.0468 16.6161 15.8332C17.4803 14.7936 18 13.4575 18 12C18 11.6329 17.967 11.2735 17.9039 10.9245Z" />
      <path d="M21.0622 7.76625L19.5317 9.29669C19.8349 10.1411 20 11.0513 20 12C20 14.0124 19.257 15.8512 18.0303 17.2571C17.8495 17.4643 17.8492 17.7782 18.0436 17.9726L18.7512 18.6801C18.9471 18.8761 19.2661 18.8761 19.451 18.6697C21.0361 16.9001 22 14.5626 22 12C22 10.4867 21.6639 9.05193 21.0622 7.76625Z" />
    </svg>
  );
};

export default Icon;
