import React from "react";
import { format } from "date-fns";
import { keyBy } from "lodash";
import Link from "next/link";

import { useContacts } from "@hooks/data/clients";
import { displayNameFromContact } from "@lib/contacts";
import pluralHelper from "@lib/utils/pluralHelper";

import ClientAvatar from "@components/Client/ClientAvatar";
import { ConditionalTeamsRenderer } from "@components/ConditionalTeamsRenderer";
import LinkIcon from "@components/Icons/LinkIcon";
import SharedIcon from "@components/Icons/SharedIcon";
import {
  Tooltip,
  TooltipSize,
  TooltipVariant,
} from "@components/Tooltips/Tooltip";

interface ClientsTooltipToggleProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  item: any;
  kind: "regular" | "detailed";
  clientId?: string;
}

const OnlyYou = () => {
  return (
    <ConditionalTeamsRenderer defaultContent={<>Only you</>}>
      <>Everyone in your team</>
    </ConditionalTeamsRenderer>
  );
};

const ClientList = ({ clients }) => {
  const num = clients.length;
  if (!num) return <OnlyYou />;

  return <div className="underline">{pluralHelper(num, "client")}</div>;
};

const Backlink: React.FC<{ link: string; text: string }> = ({ link, text }) => {
  return (
    <Link href={`/${link}`} className="flex items-center underline">
      <LinkIcon className="mr-2 transform -scale-x-1 -rotate-90" />
      <span>{text}</span>
    </Link>
  );
};
const ClientsTooltipToggle: React.FC<ClientsTooltipToggleProps> = ({
  item,
  kind,
  clientId,
}) => {
  const { active: contacts } = useContacts();

  const activeClientsMap = keyBy(contacts, "id");

  if (item.broadcasted) {
    return <>All clients</>;
  }

  if (clientId && activeClientsMap[clientId]) {
    if (item.status === "private") return <OnlyYou />;
    return (
      <Backlink
        text={displayNameFromContact(activeClientsMap[clientId])}
        link={`/contacts/${clientId}`}
      />
    );
  }

  if (!item?.sharedWith) return null;

  const { sharedWith, sharedWithData } = item;

  const activeShared: string[] = sharedWith.filter(
    (id: string) => activeClientsMap[id]
  );

  const getUnique = (array: string[]) => [...new Set(array)];
  const clientList = activeShared.map((id) => activeClientsMap[id]);
  const uniqueClientList = getUnique(clientList);
  const uniqueShared = getUnique(activeShared);

  if (!uniqueShared.length) return <OnlyYou />;

  if (uniqueShared.length === 1) {
    if (kind === "detailed")
      return (
        <Backlink
          text={displayNameFromContact(activeClientsMap[uniqueShared[0]])}
          link={`/contacts/${uniqueShared[0]}`}
        />
      );

    return (
      <div>{displayNameFromContact(activeClientsMap[uniqueShared[0]])}</div>
    );
  }

  return (
    <Tooltip
      placement="bottom-start"
      size={TooltipSize.large}
      trigger={<ClientList clients={uniqueClientList} />}
      variant={TooltipVariant.white}
    >
      <div className="p-1 w-60">
        <div className="text-grey-500 mb-4">Who can access it</div>

        <div className="space-y-4">
          {uniqueShared.map((id) => {
            if (!sharedWithData?.[id]) return null;

            return (
              <Link
                href={`/contacts/${id}`}
                key={id}
                className="flex items-center text-left"
              >
                <div className="basis-8 mr-6 relative">
                  <ClientAvatar client={activeClientsMap[id]} />
                  <div className="block rounded-full absolute -bottom-2 -right-2 w-6 h-6 bg-blue-900 text-blue-300">
                    <SharedIcon className="text-blue-300" />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div>{displayNameFromContact(activeClientsMap[id])}</div>
                  <div className="text-grey-500">
                    Shared on{" "}
                    {format(
                      sharedWithData?.[id]?.sharedWithDate,
                      "MMM d, yyyy"
                    )}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </Tooltip>
  );
};

export default ClientsTooltipToggle;
