import React, { FC, useState } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";
import Link from "next/link";

import useFormTemplates from "@hooks/use-form-templates";

import { Dialog } from "@components/Dialog";
import { textColorStatus } from "@components/FormStatusIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import LinkIcon from "@components/Icons/LinkIcon";
import TypeIllustration from "@components/TypeIllustration";

import { FormType, InfoLinkedFormsProps } from "./types";

const InfoLinkedForms: FC<InfoLinkedFormsProps> = ({
  className,
  forms = [],
  contactId,
  userId,
  label,
  onClickForm,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ],
    }
  );
  const totalForms = forms.length;
  const isMultipleForms = totalForms > 1;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { templates } = useFormTemplates(userId);
  const formWrapperStyle =
    "border-b last:border-b-0 border-grey-950 p-4 flex items-center leading-none hover:bg-grey-950 cursor-pointer";

  const toggleDialog = () => setShowDialog(!showDialog);

  const handleClickForm = (formId: string) => () => {
    onClickForm && onClickForm(formId);
  };

  const renderFormContent = (form: FormType) => {
    const icon = templates?.find(
      (item) => item.id === form.formTemplateId
      // eslint-disable-next-line
      // @ts-ignore: hook needs to be converted to ts
    )?.icon;
    return (
      <>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <TypeIllustration slug={icon} fileType="svg" className="mr-4" />
        <span className="flex-1 flex flex-col text-sm">
          {form.title}
          <span
            className={classNames(
              "text-xs mt-2 capitalize",
              textColorStatus(form.status)
            )}
          >
            {form.status}
          </span>
        </span>
        <LinkIcon className="transform rotate-90" />
      </>
    );
  };

  const renderFormWithLink = (form: FormType) => {
    return (
      <Link
        key={form.id}
        href={`/contacts/${contactId}/forms/${form.id}`}
        className={formWrapperStyle}
      >
        {renderFormContent(form)}
      </Link>
    );
  };

  const renderFormWithClick = (form: FormType) => (
    <div
      key={form.id}
      className={formWrapperStyle}
      onClick={handleClickForm(form.id)}
    >
      {renderFormContent(form)}
    </div>
  );

  const renderDialog = (
    <div
      ref={setPopperElement}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      <Dialog
        show={true}
        className="!relative overflow-hidden"
        width="80"
        noPadding
      >
        {forms.map(onClickForm ? renderFormWithClick : renderFormWithLink)}
      </Dialog>
    </div>
  );

  const renderSingleForm = () => {
    const form = forms[0];
    const wrapperStyle = classNames(
      "text-black-ink font-medium border-black-ink inline-flex items-center leading-none -ml-1 border-b"
    );
    const content = (
      <>
        <LinkIcon className="mr-1" /> {form.title}
      </>
    );
    return onClickForm ? (
      <div className={wrapperStyle} onClick={handleClickForm(form.id)}>
        {content}
      </div>
    ) : (
      <Link
        href={`/contacts/${contactId}/forms/${form.id}`}
        className={wrapperStyle}
      >
        {content}
      </Link>
    );
  };

  return (
    <div
      className={classNames(
        "relative flex items-center cursor-pointer z-10",
        className
      )}
      onMouseEnter={isMultipleForms ? toggleDialog : undefined}
      onMouseLeave={isMultipleForms ? toggleDialog : undefined}
      onClick={isMultipleForms ? toggleDialog : undefined}
      ref={setReferenceElement}
    >
      {isMultipleForms ? label : renderSingleForm()}
      {totalForms > 1 && <InfoIcon className="w-4 h-4 ml-1" />}
      {showDialog && renderDialog}
    </div>
  );
};

export default InfoLinkedForms;
