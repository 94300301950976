import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import Link from "next/link";

interface ColumnProps {
  className?: string;
  children: ReactNode[];
}

const Column: FC<ColumnProps> = ({ className, children }) => (
  <div className={classNames("truncate", className)}>
    <div className="truncate text-black-ink">{children[0]}</div>
    <div className="truncate text-sm text-grey-500">{children[1]}</div>
    {(() => {
      if (children[2]) {
        return children[2];
      }
    })()}
  </div>
);

interface ListItemProps {
  href: string;
  icon: ReactNode;
  children: ReactNode;
  className?: string;
  shallow?: boolean;
  target?: string;
  standalone?: boolean;
  component?: any;
}

// @TODO: The correct way to type it would be: `& { Column: FC<ColumnProps> }`,
//        but it's not working as expected. We need to find a way to type
//        it better.
const ListItem: FC<ListItemProps> & { Column?: any } = ({
  component: Component = "li",
  href,
  icon,
  children,
  className,
  shallow,
  target,
  standalone,
}) => {
  const columns = React.Children.toArray(children);
  const [column, ...rest] = columns;

  return (
    <Component className="border-t first:border-t-0 border-foreground/20">
      <Link
        shallow={shallow}
        href={href}
        className={classNames(
          "block  focus:outline-none  transition duration-150 ease-in-out",
          standalone && `hover:bg-accent/30 focus:bg-accent/30`,
          className
        )}
        target={target}
      >
        <div className="flex items-center py-4 sm:px-4">
          <div
            className={`min-w-0 flex-1 grid items-center ${
              columns.length === 1 ? "grid-cols-1" : "grid-cols-2"
            } sm:grid-cols-${columns.length} gap-4`}
          >
            <div className="flex items-center">
              <div className="pr-4">{icon}</div>
              {column}
            </div>
            {rest}
          </div>
        </div>
      </Link>
    </Component>
  );
};

ListItem.Column = Column;

export default ListItem;
