import React, { useMemo } from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { capitalize, filter, isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useDocument } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { useContacts } from "@hooks/data/clients";
import useContact from "@hooks/use-contact";
import { ARTEFACT_NAME_MAP } from "@lib/utils/library";

import ArtifactStatusIcon from "@components/ArtifactStatusIcon";
import { ArtifactTypes } from "@components/ArtifactStatusIcon/types";
import ClientAvatar from "@components/Client/ClientAvatar";
import InfoBoxDetailsPage from "@components/DetailsPage/InfoBoxDetailsPage";
import InfoBoxLabelDetailsPage from "@components/DetailsPage/InfoBoxLabelDetailsPage";
import InfoBoxStatusDetailsPage from "@components/DetailsPage/InfoBoxStatusDetailsPage";
import ClientsTooltipToggle from "@components/FilesAndLinks/ClientsTooltipToggle";
import { getLibrayItemStatus } from "@components/FilesAndLinks/FilesAndLinksListItem";
import ClientIcon from "@components/Icons/ClientIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import FolderIcon from "@components/Icons/FolderIcon";
import LinkedIcon from "@components/Icons/LinkedIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import InfoLinkedForms from "@components/InfoLinkedForms";
import StatusTooltip from "@components/Status/StatusTooltip";

const LibraryItemDetails: React.FC<{
  item: any;
  type: ArtifactTypes;
  clientId?: string;
  system?: boolean;
  standalone?: boolean;
}> = ({ item, type, system, clientId, standalone }) => {
  const { oid } = useAuth();
  const { contact } = useContact(clientId);
  const { active: contacts } = useContacts();
  const router = useRouter();

  const formId = item?.formId;

  const { data: form } = useDocument(
    oid && formId && clientId
      ? `users/${oid}/clients/${clientId}/forms/${formId}`
      : null
  );

  const clientsSharedWith = useMemo(() => {
    if (clientId && item.status === "shared") return [contact];

    return filter(contacts, (contact) => {
      return item?.sharedWith?.includes(contact.id);
    });
  }, [item, contacts, clientId, contact]);

  const getSection = (
    Icon: React.FC<{ className: string }>,
    title: string,
    description: string
  ) => {
    return (
      <div className="flex items-center text-black-ink">
        {<Icon className="w-8 h-8 shrink-0" />}
        <div className="flex flex-col ml-8 ">
          <div className="text-sm font-medium">{title}</div>
          <div className="text-xs text-grey-500">{description}</div>
        </div>
      </div>
    );
  };

  if (!item) return null;

  const statusTooltipContent = system ? (
    <div className="w-60">
      <div className="text-black-ink font-medium mb-2 text-lg">
        How system folders work
      </div>
      <div className="text-grey-500 text-sm mb-6">
        System folder are automatically created when you share files and links
        from a client record.
      </div>

      {getSection(
        ClientIcon,
        "Client record",
        "A file or links, private or shared is added to a client record"
      )}
      <LoopIcon className="rotate-90 text-grey-500 w-8" />
      {getSection(
        FolderIcon,
        "Client folder",
        "A shortcut is added to a dedicated client folder in your library."
      )}
    </div>
  ) : null;

  return (
    <div
      className={classNames("grid grid-cols-2", !standalone && "gap-y-4 my-5")}
    >
      <InfoBoxDetailsPage
        icon={
          <ArtifactStatusIcon
            type={type}
            variant="status-icon"
            status={getLibrayItemStatus(item)}
          />
        }
        title="Status"
      >
        <InfoBoxStatusDetailsPage
          formattedStatus={capitalize(getLibrayItemStatus(item))}
          icon={
            <StatusTooltip
              type={item.broadcasted ? "broadcasted" : ARTEFACT_NAME_MAP[type]}
              dialogContent={statusTooltipContent}
            />
          }
        />
      </InfoBoxDetailsPage>
      {item.id !== "clients" && (
        <InfoBoxDetailsPage
          title="Who can access it"
          icon={
            isEmpty(clientsSharedWith) ? (
              // @ts-expect-error: mandatory props
              <ClientIcon />
            ) : (
              <ClientAvatar client={clientsSharedWith[0]} />
            )
          }
          iconClassName="bg-grey-950"
        >
          <InfoBoxLabelDetailsPage>
            <ClientsTooltipToggle
              clientId={clientId}
              kind="detailed"
              item={item}
            />
          </InfoBoxLabelDetailsPage>
        </InfoBoxDetailsPage>
      )}
      {item.createdAt && (
        <InfoBoxDetailsPage
          iconClassName="bg-grey-950"
          icon={<ClockIcon />}
          title="Created on"
        >
          <InfoBoxLabelDetailsPage>
            {format(item.createdAt.toDate?.() || item.createdAt, "eee, PP")}
          </InfoBoxLabelDetailsPage>
        </InfoBoxDetailsPage>
      )}
      {form && (
        <InfoBoxDetailsPage
          iconClassName="bg-grey-950"
          icon={<LinkedIcon />}
          title="Linked form"
        >
          <InfoLinkedForms
            className="ml-1"
            contactId={clientId}
            userId={oid}
            forms={[form]}
            onClickForm={() =>
              router.push(`/contacts/${clientId}/forms/${formId}`)
            }
          />
        </InfoBoxDetailsPage>
      )}
    </div>
  );
};

export default LibraryItemDetails;
