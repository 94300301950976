import { Dispatch, SetStateAction, useMemo } from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { capitalize, compact, filter, isEmpty, split, toUpper } from "lodash";

import { useContacts } from "@hooks/data/clients";
import useContact from "@hooks/use-contact";
import { useMatchMedia } from "@hooks/use-match-media";
import { displayNameFromContact } from "@lib/contacts";
import { getClientsSharedWithText } from "@lib/files-and-links";
import { LibraryItemType, SharedLibraryType } from "@lib/shared-types";

import ArtifactStatusIcon from "@components/ArtifactStatusIcon";
import ClientsTooltipToggle from "@components/FilesAndLinks/ClientsTooltipToggle";
import IconAction from "@components/IconAction";
import BroadcastIcon from "@components/Icons/BroadcastIcon";
import FolderMoveIcon from "@components/Icons/FolderMoveIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import ShareIcon from "@components/Icons/ShareIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import UnbroadcastIcon from "@components/Icons/UnbroadcastIcon";
import ListItem from "@components/ListItem";
import { useSelectableListContext } from "@components/SelectableList";

import { LibraryAction, ModalInfoType } from "./FilesAndLinksList";

interface Props {
  data: any;
  setModalInfo: Dispatch<SetStateAction<ModalInfoType>>;
  index: number;
  hideSharedColumn?: boolean;
  moveDisabled?: boolean;
  renameDisabled?: boolean;
  editDisabled?: boolean;
  deleteDisabled: boolean;
  broadcastDisabled?: boolean;
}
export const LibraryItemBasicInfo: React.FC<{
  data: SharedLibraryType & { type: LibraryItemType; clientId: string };
}> = ({ data }) => {
  const { active: contacts } = useContacts();
  const { contact: client } = useContact(data?.clientId);

  const isDesktop = useMatchMedia("sm");

  const type = data.type;

  // Get the contacts (clients) with whom this file/link has been shared with, if applicable
  const clientsSharedWith = useMemo(() => {
    return filter(
      contacts,
      (contact) => data?.sharedWith?.includes(contact.id)
    );
  }, [contacts, data]);

  const getTextClass = () => {
    if (data.broadcasted) return "text-violet-300";
    if (data.status === "shared") return "text-blue-300";
    return "text-black-ink";
  };

  const getLabel = () => {
    if (data.broadcasted) return "Broadcasted";
    if (data.status === "private") return "Private";
    if (data.status === "shared") {
      if (client) return `Shared with ${displayNameFromContact(client)}`;
      if (!isEmpty(clientsSharedWith))
        return `Shared with ${getClientsSharedWithText(clientsSharedWith)}`;
    }
    return null;
  };

  const renderMobileStatus = () => (
    <span className={getTextClass()}>{getLabel()}</span>
  );

  return isDesktop ? renderSubtitle(data, type) : renderMobileStatus();
};

export const LibraryItemTitle: React.FC<{ data: any }> = ({ data }) => {
  const type = data.type;

  return <span>{type === "files" ? data.fileName : data.title}</span>;
};

export const getLibrayItemStatus = (data: SharedLibraryType) => {
  if (data.broadcasted) return "broadcasted";
  return data.status;
};

export const LibraryItemIcon: React.FC<{ data: any }> = ({ data }) => {
  const url = data.type !== "links" && (data.url || data.imageURL);
  const faviconUrl = data.type === "links" ? data.favicon : undefined;

  return (
    <ArtifactStatusIcon
      status={getLibrayItemStatus(data)}
      // TODO Fix any
      type={data.type}
      url={url}
      className={url ? "" : "border-2 border-foreground/10"}
      iconUrl={faviconUrl}
    />
  );
};

export const renderSubtitle = (data: any, type: string) => {
  const status = capitalize(getLibrayItemStatus(data));

  const CLASS_MAP = {
    shared: "text-blue-300",
    private: "text-grey-500",
    system: "text-action-500",
    broadcasted: "text-violet-300",
  };

  const getContent = (type: string) => (
    <div className="text-xs">
      <span
        className={classNames(
          CLASS_MAP[getLibrayItemStatus(data)],
          "font-semibold"
        )}
      >
        {status}
      </span>
      {` · `}
      <span className="text-foreground/50">{type}</span>
    </div>
  );

  if (type === "links") return getContent(`Web link`);
  if (type === "files") {
    if (data.contentType)
      return getContent(toUpper(split(data.contentType, "/", 2)[1]));
    return getContent("File");
  }
  if (type === "folders") return getContent("Folder");

  return getContent(status);
};

const FilesAndLinksListItem: React.FC<Props> = ({
  data,
  setModalInfo,
  index,
  hideSharedColumn,
  moveDisabled,
  renameDisabled,
  editDisabled,
  deleteDisabled,
  broadcastDisabled,
}) => {
  const isDesktop = useMatchMedia("sm");

  const { clearSelected, toggleSelect } = useSelectableListContext();

  const renderDate = () => format(data.createdAt, "eee, MMM d, yyyy");

  const dropdownItems = compact([
    {
      type: "button",
      title: "Share",
      Icon: ShareIcon,
      onClick: () => setModalInfo({ action: LibraryAction.Share, index }),
    },
    !broadcastDisabled &&
      !data.path && {
        type: "button",
        title: data.broadcasted ? "Stop broadcast" : "Broadcast",
        Icon: data.broadcasted ? UnbroadcastIcon : BroadcastIcon,
        onClick: () => setModalInfo({ action: LibraryAction.Broadcast, index }),
      },
    !renameDisabled && {
      type: "button",
      title: "Rename",
      Icon: PencilIcon,
      onClick: () => setModalInfo({ action: LibraryAction.Rename, index }),
    },
    !editDisabled && {
      type: "button",
      title: "Edit",
      Icon: PencilIcon,
      onClick: () => setModalInfo({ action: LibraryAction.Edit, index }),
    },
    !moveDisabled && {
      type: "button",
      title: "Move",
      Icon: FolderMoveIcon,
      onClick: () => {
        clearSelected();
        toggleSelect(data);
        setModalInfo({ action: LibraryAction.Move, index: null });
      },
    },
    !deleteDisabled && {
      type: "button",
      title: "Delete",
      Icon: TrashIcon,
      onClick: () => setModalInfo({ action: LibraryAction.Delete, index }),
      variant: "delete",
    },
  ]);

  return (
    <>
      <ListItem
        key={data.id}
        href={data.link ?? "/resources"}
        icon={<LibraryItemIcon data={data} />}
        className="group relative"
      >
        <ListItem.Column>
          <LibraryItemTitle data={data} />
          <LibraryItemBasicInfo data={data} />
        </ListItem.Column>
        {/* match media is used here instead of className="hidden sm:block" so that
        the right number of columns are sent to ListItem, and it can set grid-cols
        number in a compatible way (the component is used in a number of places
        with a set minimum of 2 cols -- whereas this just has 1 in mobile) */}
        {isDesktop && !hideSharedColumn ? (
          <ClientsTooltipToggle item={data} kind="regular" />
        ) : null}
        {isDesktop ? (
          <>
            <div>
              <div className="truncate text-foreground">
                {data.createdAt && renderDate()}
              </div>
            </div>
            {data.status !== "system" && (
              <IconAction
                className="top-0 bottom-0 right-4 m-auto"
                type="dropdown"
                dropdownItems={dropdownItems}
              />
            )}
          </>
        ) : null}
      </ListItem>
    </>
  );
};

export default FilesAndLinksListItem;
