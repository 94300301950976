import {
  shareBaseLibraryItemWithClient,
  updateLibraryItem,
} from "api-services/definitions/library";
import { api } from "api-services/endpoints";
import { LibraryItem } from "types/db/library";
import { LibraryResourceType } from "types/db/shared";

// TODO move somwhere when working on endpoints phase
export const updateItem = async (
  userId: string,
  id: string,
  resourceType: LibraryResourceType,
  data: LibraryItem
) => {
  return api(updateLibraryItem, {
    path: { resourceId: id, resourceType, userId },
    body: data,
  });
};

export const shareItemWithClient = async (
  userId: string,
  id: string,
  resourceType: LibraryResourceType,
  clientId: string
) => {
  return api(shareBaseLibraryItemWithClient, {
    path: { resourceId: id, resourceType: resourceType, userId },
    body: {
      clientIds: [clientId],
      merge: true,
    },
  });
};
