export const loadingHelper = <T>(data: T) => {
  return {
    loading: !data,
    data,
  };
};

export const filterDeleted = <T extends { status: string }>(
  items: T[] | undefined
) => items?.filter((item) => item.status !== "deleted");
