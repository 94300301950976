import React from "react";
import classNames from "classnames";

import CheckIcon from "./Icons/CheckIcon";
import ClockIcon from "./Icons/ClockIcon";
import DocDraftIcon from "./Icons/DocDraftIcon";
import SharedIcon from "./Icons/SharedIcon";
import ViewOnIcon from "./Icons/ViewOnIcon";

export function backgroundColorStatus(status) {
  switch (status) {
    case "draft":
    case "scheduled":
      return "bg-grey-900";
    case "shared":
      return "bg-blue-900";
    case "viewed":
      return "bg-action-700";
    case "submitted":
      return "bg-green-600";
    default:
      return "bg-grey-900";
  }
}

export function textColorStatus(status) {
  switch (status) {
    case "draft":
    case "scheduled":
      return "text-grey-500";
    case "shared":
      return "text-blue-300";
    case "viewed":
      return "text-action-400";
    case "submitted":
      return "text-green-200";
    default:
      return "text-grey-500";
  }
}

export function iconStatus(status) {
  switch (status) {
    case "draft":
      return <DocDraftIcon />;
    case "shared":
      return <SharedIcon />;
    case "viewed":
      return <ViewOnIcon />;
    case "submitted":
      return <CheckIcon />;
    case "scheduled":
      return <ClockIcon />;
    default:
      return <DocDraftIcon />;
  }
}

const FormStatusIcon = ({
  status,
  className,
}: {
  status: string;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "rounded-full h-10 w-10 flex items-center justify-center",
        textColorStatus(status),
        backgroundColorStatus(status),
        className
      )}
    >
      {iconStatus(status)}
    </div>
  );
};

export default FormStatusIcon;
